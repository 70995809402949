import { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import SignalWifiStatusbarNullIcon from "@mui/icons-material/SignalWifiStatusbarNull";

import { CustomDetailsPage } from "components/DetailsPage/DetailsPage";
import DynamicForm from "components/Forms/generic/DynamicForm";
import MappingScheme from "components/MappingScheme";
import ModuleSignal from "components/ModuleSignal";
import ContentLayout from "components/layouts/ContentLayout";
import DynamicDialog from "components/layouts/Dialog";
import useDialogShowState from "hooks/useDialogShowState";
import { FieldDescriptor } from "models/fieldsEntities.model";
import Product from "models/resources/product.model";
import apiClient from "services/api";
import { getDataById } from "services/requests/listData";

import IngredientBoard from "./custom/Ingredients/IngredientBoard";
import ProductsBoard from "./custom/Products/ProductsBoard";
import useMachineDetails, { ButtonProductMapper } from "./useMachineDetails";
import { createSchemeMatrix } from "./utils";

const MachineDetails = () => {
  const { t } = useTranslation();
  const {
    loadImage,
    machineData,
    setMachineData,
    machineProducts,
    refetchFlag,
    refetchMapper,
    setMachineProducts,
    machineIngredients,
    setMachineIngredients,
    rechargeQuantities,
    productType,
    setProductType,
    showEditDialog,
    selectedEditIngredient,
    handleEditIngredientChange,
    buttonProductMappers,
    setButtonProductMappers,
    setShowEditDialog,
    attachProduct,
    attachIngredient,
    createSignalMapping,
    image,
    setRefechtFlag,
  } = useMachineDetails();
  const { id } = useParams();

  const { show, handleShowStateChange } = useDialogShowState();
  const capacityEditDialog = useDialogShowState();

  useEffect(() => {
    setMachineProducts(machineData?.products ?? []);
    setMachineIngredients(machineData?.ingredients ?? []);

    if (machineData?.product_type !== null) {
      setProductType(machineData?.product_type ?? "");
    }
  }, [machineData]);

  useEffect(() => {
    if (!machineData?.id) return;
    apiClient<ButtonProductMapper[]>({
      url: `machines/${machineData?.id}/button-product-mapper`,
      method: "get",
    }).then((response) => {
      setButtonProductMappers(response.data);
    });
  }, [machineData?.machine_blueprint, refetchMapper]);

  useEffect(() => {
    if (!id) return;
    getDataById(setMachineData, `machines/${id}`);
    loadImage();
  }, [id, refetchFlag]);

  let fields: FieldDescriptor[] = [
    {
      value: machineData?.name,
      label: "Machine name",
      type: "InfoText",
      show: true,
    },
    {
      value: machineData?.pin,
      label: "Pin",
      type: "TextField",
      show: true,
    },
    {
      value: Number(machineData?.minimum_payment_amount).toFixed(2),
      label: t("Minimal transaction sum"),
      type: "InfoText",
      show: true,
    },
    {
      value: t(machineData?.type ?? ""),
      label: "Machine Type",
      type: "InfoText",
      show: true,
    },
    {
      value: machineData?.currency,
      label: "Currency",
      type: "InfoText",
      show: true,
    },
    {
      value: `${machineData?.location.name}, ${machineData?.location.address}`,
      id: machineData?.location_id,
      label: "Location",
      type: "Link",
      route: "locations",
      show: true,
    },
    {
      value: machineData?.company.display_name || machineData?.company.name,
      id: machineData?.company?.id,
      label: "Company",
      type: "Link",
      route: "companies",
      show: true,
    },
    {
      value: machineData?.module?.serial,
      id: machineData?.module?.id,
      label: "Module Serial",
      type: "Link",
      route: "modules",
      show: true,
    },
    {
      value: machineData?.entity_category?.name,
      label: "Entity Category",
      type: "InfoText",
      show: true,
    },
    {
      value: machineData?.module?.state ? machineData?.module?.serial : "---",
      label: "QR code",
      type: machineData?.module?.state ? "QR" : "TextField",
      show: true,
    },
    {
      value: machineData?.module?.signal_strength ? (
        <ModuleSignal
          signal={machineData?.module?.signal_strength ?? null}
          noLabel={true}
        />
      ) : (
        <SignalWifiStatusbarNullIcon />
      ),
      label: "Module Signal Strength",
      type: "Icon",
      show: true,
    },
    {
      value: machineData?.machine_blueprint?.name,
      label: "Blueprint",
      type: "Link",
      route: "vending/blueprints",
      id: machineData?.machine_blueprint?.id,
      show: true,
    },
    {
      value: t(machineData?.product_type!),
      label: t("ProductType"),
      type: "InfoText",
      show: true,
    },
    {
      value:
        machineData?.module?.state === "up" ? (
          <FiberManualRecordIcon sx={{ color: "#4CAF50" }} />
        ) : (
          <FiberManualRecordIcon sx={{ color: "#FF6347" }} />
        ),
      label: "Module State",
      type: "Icon",
      show: true,
    },
    {
      value: machineData?.pin,
      label: "Pin",
      type: "InfoText",
      show: true,
    },
    {
      value: machineData?.comments,
      label: "Comments",
      type: "DescriptiveInfoText",
      show: true,
    },
    {
      value: image,
      label: "Image",
      type: "ImageFile",
      inlineStyle: {
        width: "400px",
        height: "400px",
      },
      show: true,
    },

    {
      type: "CustomComponent",
      value: (
        <DynamicDialog
          isOpen={false}
          openMessage="Map products to buttons"
          title="Scheme"
          hideActions
          component={
            <Fragment>
              <MappingScheme
                label="product"
                mappingOptions={machineData?.products as Product[]}
                rowsCount={machineData?.machine_blueprint?.number_of_rows ?? 0}
                columnsCount={
                  machineData?.machine_blueprint?.number_of_columns ?? 0
                }
                signalMatrix={createSchemeMatrix(
                  machineData?.machine_blueprint?.number_of_rows ?? 0,
                  machineData?.machine_blueprint?.number_of_columns ?? 0,
                  machineData?.machine_blueprint?.machine_blueprint_signal_mappings.map(
                    (sm) => ({
                      row: sm.row,
                      column: sm.column,
                      value: sm.button_signal,
                      product_id: buttonProductMappers?.find(
                        (bpm) => bpm.row === sm.row && bpm.column === sm.column,
                      )?.product_id,
                    }),
                  ) ?? [],
                )}
                onSubmit={createSignalMapping}
                // TODO: Add copy machine functionallity in the future.
                // customComponent={
                //   <DynamicDialog
                //     buttonSx={{
                //       marginBottom: "10px",
                //       marginLeft: "auto",
                //       display: "flex",
                //     }}
                //     isOpen={machineCopyDialog.show}
                //     openMessage="Copy from machine"
                //     title="Copy from machine"
                //     hideActions
                //     onClick={machineCopyDialog.handleShowStateChange}
                //     onCancel={machineCopyDialog.handleShowStateChange}
                //     component={
                //       <CopySchemeForm
                //       productType={productType}
                //         onSubmit={(id) => {
                //           copyMachineScheme(id);
                //           machineCopyDialog.handleShowStateChange();
                //         }}
                //       />
                //     }
                //   />
                // }
              />

              {buttonProductMappers
                .filter(
                  (bpm, index, self) =>
                    index ===
                    self.findIndex((t) => t.product_id === bpm.product_id),
                )
                .sort((a, b) => {
                  if (a.product_id < b.product_id) return -1;
                  if (a.product_id > b.product_id) return 1;
                  return 0;
                })
                .map((bpm) => (
                  <div>{`${bpm.product_id} - ${bpm.product.display_name}`}</div>
                ))}
            </Fragment>
          }
        />
      ),
      label: "",

      show: true,
    },
    {
      type: "BlankSpace",
      label: "",
      show: true,
      value: "",
      inlineStyle: { height: "10px" },
    },
    {
      type: "Button",
      label: "",
      show: true,
      value: t("Recharge Quantities"),
      text: "Are you sure you want to recharge the machine quantities?",
      onClick: () => rechargeQuantities(),
    },
  ];

  enum DialogType {
    PRODUCT = "product",
    INGREDIENT = "ingredient",
  }

  return (
    <ContentLayout
      title={`${t("Machines")} > ${machineData?.name ?? ""}`}
      filters={false}
    >
      {showEditDialog?.display && (
        <DynamicDialog
          hideActions={true}
          title={t(
            `Edit ${
              showEditDialog?.type === DialogType.PRODUCT
                ? "Product"
                : "Ingredient"
            }`,
          )}
          key={`edit-${showEditDialog?.editId}`}
          component={
            <DynamicForm
              key={showEditDialog?.editId}
              isResourceListedInTable={true}
              mode="edit"
              resource={
                showEditDialog?.type === DialogType.PRODUCT
                  ? productType === "packaged"
                    ? "packet-product"
                    : "recipe-product"
                  : DialogType.INGREDIENT
              }
              call={
                showEditDialog?.type === DialogType.PRODUCT
                  ? "products"
                  : "ingredients"
              }
              dynamicClass={() =>
                `edit-form__${
                  showEditDialog?.type === DialogType.PRODUCT
                    ? "product"
                    : "ingredient"
                }`
              }
              editId={showEditDialog?.editId}
              exitAction={setShowEditDialog}
              updateData={setMachineData}
              tableData={machineData}
            />
          }
          isOpen={true}
          hideButton={true}
          exitAction={setShowEditDialog}
        />
      )}

      <CustomDetailsPage
        data={machineData}
        setData={(v) => {
          setMachineData(v);
          setRefechtFlag((old) => !old);
        }}
        resource={"machine"}
        call={"machines"}
        fields={fields}
      />
      <div style={{ height: "20px" }} />
      <ProductsBoard
        show={show}
        productType={productType}
        handleShowStateChange={handleShowStateChange}
        machineData={machineData}
        machineProducts={machineProducts}
        setMachineData={setMachineData}
        attachProduct={attachProduct}
        setMachineProducts={setMachineProducts}
        setRefechtFlag={setRefechtFlag}
      />
      <div style={{ height: "20px" }} />
      <IngredientBoard
        machineData={machineData}
        productType={productType}
        machineProducts={machineProducts}
        setMachineProducts={setMachineProducts}
        selectedEditIngredient={selectedEditIngredient}
        capacityEditDialog={capacityEditDialog}
        machineIngredients={machineIngredients}
        setMachineIngredients={setMachineIngredients}
        handleEditIngredientChange={handleEditIngredientChange}
        attachIngredient={attachIngredient}
        setMachineData={setMachineData}
        setRefechtFlag={setRefechtFlag}
      />
    </ContentLayout>
  );
};

export default MachineDetails;
