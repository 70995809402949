import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import { CellValue, Column } from "components/Table/Table";
import { CredentialsToken } from "models/resources/company.model";
import { getLocalizedDate } from "utils/lozalizeDateTime";

// This file is 'tsx' format because of the JSX Elements 'FiberManualRecordIcon' that are used for the active column of the table.
export const tokenTableColumns: Column<CredentialsToken>[] = [
  {
    label: "Description",
    name: "description",
    getData: (data): CellValue => data.description ?? "---",
    show: () => {
      return true;
    },
  },
  {
    label: "Callback url",
    name: "callbackUrl",
    getData: (data): CellValue => data.callback_url ?? "---",
    show: () => {
      return true;
    },
  },
  {
    label: "Active",
    name: "active",
    show: () => {
      return true;
    },
    padding: "6px 50px",
    getData: (data): CellValue =>
      data.active ? (
        <FiberManualRecordIcon sx={{ color: "#4CAF50" }} />
      ) : (
        <FiberManualRecordIcon sx={{ color: "#FF6347" }} />
      ),
  },
  {
    label: "Sandbox",
    name: "sandbox",
    show: () => {
      return true;
    },
    padding: "6px 50px",
    getData: (data): CellValue =>
      data.sandbox ? (
        <FiberManualRecordIcon sx={{ color: "#4CAF50" }} />
      ) : (
        <FiberManualRecordIcon sx={{ color: "#FF6347" }} />
      ),
  },
  {
    label: "Token",
    name: "token",
    show: () => {
      return true;
    },
    padding: "6px 50px",
    getData: (data): CellValue => data.value,
  },
  {
    label: "Last used",
    name: "lastUsed",
    getData: (data): CellValue =>
      data.last_used !== null ? getLocalizedDate(data.last_used) : "---",
    show: () => {
      return true;
    },
    padding: "6px 50px",
  },
  {
    label: "Valid until",
    name: "validUntil",
    getData: (data): CellValue => getLocalizedDate(data.valid_until),
    show: () => {
      return true;
    },
  },
  {
    label: "Actions",
    name: "actions",
    getData: (data): CellValue => data.id,
    show: () => {
      return true;
    },
    align: "center",
    width: "5%",
  },
];
