import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";

import { isOnNonAuthRoute } from "context/AuthContext";
import RequestParams from "models/requestParams.model";
import { ResponseDataError } from "models/responseData.model";

import parseQueryParams from "./parseQueryParams";

export function setupAPIClient() {
  let headers: { Authorization?: string; Accept: string } = {
    Accept: "application/json",
  };

  const api = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: headers,
  });

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error: AxiosError<any>) => {
      if (error.response?.status === 401 && (await !isOnNonAuthRoute())) {
        if (window.location.pathname != "/login")
          window.location.href = "/login";
      }

      return Promise.reject({
        statusCode: error.response?.status,
        message:
          error.response?.status !== 422
            ? error.response?.data.reason
            : Object.values(error.response.data.errors[0]).join(" "),
      } as ResponseDataError);
    },
  );

  return api;
}

type Method = keyof typeof api; // Used for dynamic calling a method on the api object.
// Generic API calls with optional 'bodyData' and 'params'
// and dynamic request 'method', 'url' and resource return type.
// Returns ResponseData with status code that can be used for error handling.
export const apiClient = async <TModel>(
  { url, queryParams, method, bodyData }: RequestParams,
  signal?: any, // Prototype for foture functionallity for handling multiple requests without having race condition.
) => {
  const queryString: string =
    typeof queryParams !== "undefined" ? parseQueryParams(queryParams) : "";
  return await (api[method.toLowerCase() as Method] as AxiosInstance)(
    url?.concat(queryString),
    {
      ...bodyData,
      signal: signal,
    } as AxiosRequestConfig,
  ).then((response) => {
    return {
      statusCode: response.status,
      pagination: response?.data.pagination,
      data: response.data.data,
    };
  });
};

export const api = setupAPIClient();

export default apiClient;
