import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import LineChart from "components/Chart/LineChart";
import {
  CustomTable,
  Column,
  TableSettings,
  CellValue,
} from "components/Table/Table";
import ContentLayout from "components/layouts/ContentLayout";
import useFilterContext from "context/FilterContext";
import QueryParams, { RequestPagination } from "models/queryParams.model";
import OrderModel from "models/resources/order.model";
import Order from "models/resources/order.model";
import { TransactionBehaviour } from "models/resources/transaction_behaviour.model";
import { ResponseDataTable } from "models/responseData.model";
import apiClient from "services/api";
import { getData } from "services/requests/listData";
import { getLocalizedDate, getLocalizedTime } from "utils/lozalizeDateTime";
import { getTransactionsBehaviourData } from "utils/transactionBehaviour";

const OrderTable = () => {
  const [getOrdersData, setOrdersData] =
    useState<ResponseDataTable<OrderModel[]>>();
  const [getTransactionsBehaviour, setTransactionBehaviour] = useState<
    TransactionBehaviour[]
  >([]);
  const { filter, handleFilterChange } = useFilterContext();
  const { t } = useTranslation();

  const setData = (queryParams: QueryParams, setDataLoading: any) => {
    getData(setOrdersData, "orders", queryParams, setDataLoading);
    getTransactionsBehaviourData(
      queryParams.pagination!,
      setTransactionBehaviour,
      filter,
    );
  };

  const columns: Column<Order>[] = [
    {
      label: t("Order Date"),
      name: "transactionsDate",
      getData: (data): CellValue => getLocalizedDate(data.inserted_at),
      show: () => {
        return true;
      },
    },
    {
      label: t("Order Time"),
      name: "transactionsTime",
      getData: (data): CellValue => getLocalizedTime(data.inserted_at),
      show: () => {
        return true;
      },
    },
    {
      label: t("Product number"),
      name: "productNumber",
      getData: (data): CellValue => data.product_number ?? "---",
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "product_number" },
      align: "left",
    },
    {
      label: t("Unit price"),
      name: "unitPrice",
      getData: (data): CellValue =>
        data.product_price
          ? `${Number(data.product_price).toFixed(2)}  ${t(data.currency)}`
          : "",
      show: () => {
        return true;
      },
    },
    {
      label: t("Quantity"),
      name: "quantity",
      getData: (data): CellValue => Number(data.quantity).toFixed(2),
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "quantity" },
    },
    {
      label: t("Order total"),
      name: "orderTotal",
      getData: (data): CellValue =>
        data.product_price && data.quantity
          ? `${(+data.quantity * +data.product_price).toFixed(2)}  ${t(
              data.currency,
            )}`
          : "",
      show: () => {
        return true;
      },
    },
    {
      label: t("Status"),
      name: "status",
      getData: (data): CellValue => (data.status ? t(data.status) : "---"),
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "status" },
    },
    {
      label: t("Company"),
      name: "company",
      getData: (data): CellValue => (
        <Link
          to={{
            pathname: `/account/companies/${data.company_id}`,
          }}
        >
          {data.company.display_name || data.company.name}
        </Link>
      ),
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "company_id" },
    },
    {
      label: t("Payment point"),
      name: "paymentPoint",
      getData: (data): CellValue => data.object_entity?.name,
      show: () => {
        return true;
      },
      sort: {
        isSortable: true,
        field: "object_entity.name",
      },
    },
  ];

  const tableOptions: TableSettings = {
    pagination: true,
    page: 0,
    rowsPerPage: 25,
    field: "id",
    order: "asc",
  };

  return (
    <ContentLayout title={t("Orders")} isDateRanged>
      <LineChart
        dataArray={getTransactionsBehaviour || []}
        dateRange={filter.dateRange}
      />
      <CustomTable
        isSearchable={false}
        title={t("Orders")}
        tableData={getOrdersData}
        fetchPaginatedData={setData}
        setTableData={setOrdersData}
        columns={columns}
        tableOptions={tableOptions}
        call="orders"
        resource={"Order"}
        exportProp={"orders"}
        hadPDF={true}
      />
    </ContentLayout>
  );
};

export default OrderTable;
