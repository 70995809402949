import DynamicDialog from "components/layouts/Dialog";
import useDialogShowState from "hooks/useDialogShowState";
import { DatabaseEntity } from "models/responseData.model";

import MappingForm from "./MappingForm";

interface SchemeButton {
  indexRow: number;
  indexColumn: number;
  signal: { id?: number; value: string };
  label: string;
  mappingOptions?: DatabaseEntity[];
  onSubmit(selectedBtnValue: {
    row: number;
    column: number;
    value: string;
  }): void;
}

const SchemeButton = ({
  indexRow,
  indexColumn,
  signal,
  label,
  mappingOptions,
  onSubmit,
}: SchemeButton) => {
  const { show, handleShowStateChange } = useDialogShowState();

  return (
    <DynamicDialog
      key={indexColumn * (indexRow + 1)}
      openMessage={signal.value}
      title="Mapping"
      isOpen={show}
      hideActions
      onClick={handleShowStateChange}
      onCancel={handleShowStateChange}
      component={
        <MappingForm
          label={label}
          buttonSignal={+signal.value ?? 0}
          dropdown={
            typeof mappingOptions !== "undefined"
              ? {
                  options: mappingOptions,
                  selected: mappingOptions.find(
                    //@ts-ignore
                    (o) => o.id === signal.id,
                  ),
                }
              : undefined
          }
          onSubmit={(selectedValue) => {
            onSubmit({
              row: indexRow + 1,
              column: indexColumn + 1,
              value: selectedValue,
            });

            handleShowStateChange();
          }}
        />
      }
      buttonSx={{
        marginBottom: "10px",
        marginLeft: "10px",
      }}
    />
  );
};

export default SchemeButton;
