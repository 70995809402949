import * as _ from "lodash";

import QueryParams, { Filter } from "../../models/queryParams.model";
import { RequestPagination } from "../../models/queryParams.model";
import RequestParams from "../../models/requestParams.model";
import { DatabaseEntity } from "../../models/responseData.model";
import apiClient from "../api";

export const getData = async (
  setData: any,
  url: string,
  queryParams: QueryParams,
  // Prototype for foture functionallity for handling multiple requests without having race condition.
  setDataLoading?: any,
  signal?: any,
) => {
  if (setDataLoading) setDataLoading(true);

  let queryFilters = {};
  if (queryParams?.filter)
    queryFilters = Object.fromEntries(
      Object.entries(queryParams?.filter).filter(
        ([key, value]) =>
          // Remove empty entries and empty string for search query, but leave boolean values e.g. deleted: true.
          (typeof value === "boolean" || (value !== "" && !_.isEmpty(value))) && // @ts-ignore
          value?.startDate !== null, // Needed for returning back to the promotion table page from the promotion details page.
      ),
    );

  const qp: QueryParams = {
    filter: queryFilters,
    pagination: queryParams?.pagination ?? { page: 1, perPage: 10 },
    sort: queryParams?.sort ?? { field: "id", order: "DESC" },
  };

  const requestParams: RequestParams = {
    url: url,
    queryParams: qp,
    method: "get",
  };
  await apiClient(requestParams, signal)
    .then((response) => {
      if (setDataLoading) setDataLoading(false);
      setData(response as any);
      return response;
    })
    .catch((error) => console.error(error));
};

export const getDataById = async (
  setData: any,
  url: string,
  setDataLoading?: any,
) => {
  const requestParams: RequestParams = {
    url,
    method: "get",
  };
  await apiClient(requestParams).then((response) => {
    setData(response.data as any);
    if (setDataLoading) setDataLoading(false);
    return response.data;
  });
};

export const deleteDataById = async (resource: string, id: number) => {
  const url: string = `${resource}/${id}`;

  await apiClient<DatabaseEntity[]>({
    url: url,
    method: "delete",
  });
};
