import { useTranslation } from "react-i18next";

import CircleIcon from "@mui/icons-material/Circle";
import { TableCell, TableRow } from "@mui/material";

import { convertUTCDateToLocalDate } from "components/DatePicker/utils";
import { CustomDetailsPage } from "components/DetailsPage/DetailsPage";
import ContentLayout from "components/layouts/ContentLayout";
import { FieldDescriptor } from "models/fieldsEntities.model";
import { getLocalizedDate } from "utils/lozalizeDateTime";

import usePriceAdjustmentDetails from "./usePriceAdjustmentDetails";

const PriceAdjustmentDetails = () => {
  const { productsData, setProductsData } = usePriceAdjustmentDetails();

  const { t } = useTranslation();

  if (!productsData) return <></>;

  const fields: FieldDescriptor[] = [
    {
      value: productsData?.name,
      label: t("Name"),
      type: "InfoText",
      show: true,
    },
    {
      value: productsData?.display_name,
      label: t("Display Name"),
      type: "InfoText",
      show: true,
    },
    {
      value: productsData.company.display_name || productsData.company.name,
      id: productsData?.company_id,
      label: "Company",
      type: "Link",
      route: "companies",
      show: true,
    },
    {
      value: Number(productsData.gift_card.discount_value).toFixed(2),
      label: "Price difference",
      type: "InfoText",
      show: true,
    },
    {
      value: getLocalizedDate(productsData?.valid_from),
      label: "Valid from",
      type: "InfoText",
      show: true,
    },
    {
      value: getLocalizedDate(productsData?.valid_to),
      label: t("Valid to"),
      type: "InfoText",
      show: true,
    },
    {
      value: (
        <CircleIcon
          sx={{ color: productsData.active ? "#4CAF50" : "#FF6347" }}
        />
      ),
      label: t("Public"),
      type: "Icon",

      show: true,
    },
    {
      value: productsData?.description,
      label: "Description",
      type: "DescriptiveInfoText",
      show: true,
    },
    {
      value: "",
      label: "",
      type: "BlankSpace",
      show: true,
      inlineStyle: {
        margin: "20px",
      },
    },
    {
      label: t("Win conditions"),
      value: "win_conditions",
      type: "Table",
      show: true,
      headers: [t("Win Conditions"), t("Starts at"), t("Ends at")],
      columns: productsData?.win_conditions.map((e: any, i: any) => {
        const dateFrom = e.from.split(":");
        const dateTo = e.to.split(":");

        const [hoursFrom, minutesFrom, _secondsFrom] = dateFrom;

        let localDateFrom = new Date();
        localDateFrom.setHours(hoursFrom, minutesFrom);
        localDateFrom = convertUTCDateToLocalDate(localDateFrom);

        const [hoursTo, minutesTo, _secondsto] = dateTo;

        let localDateTo = new Date();
        localDateTo.setHours(hoursTo, minutesTo);
        localDateTo = convertUTCDateToLocalDate(localDateTo);

        return (
          <TableRow key={`${i}-${e.id}`}>
            <TableCell sx={{ width: "20%" }}>{t(e.type)}</TableCell>
            <TableCell sx={{ width: "20%" }}>
              {localDateFrom.getHours()}:
              {localDateFrom.getMinutes().toString().padStart(2, "0")}
            </TableCell>
            <TableCell>
              {localDateTo.getHours()}:
              {localDateTo.getMinutes().toString().padStart(2, "0")}
            </TableCell>
          </TableRow>
        );
      }),
    },
    {
      label: t("Products"),
      value: "products",
      type: "Table",
      show: true,
      headers: [t("Name"), t("Price"), t("Current price")],
      columns: productsData?.products.map((product, idx) => {
        return (
          <TableRow>
            <TableCell style={{ width: "20%" }}>{product.name}</TableCell>
            <TableCell style={{ width: "20%" }}>
              {Number(product.price)?.toFixed(2)}
            </TableCell>
            <TableCell>
              {product.current_price
                ? Number(product.current_price)?.toFixed(2)
                : "-"}
            </TableCell>
          </TableRow>
        );
      }),
    },
  ];

  return (
    <ContentLayout
      title={`${t("Price Adjustment")} > ${productsData?.name ?? ""}`}
      filters={false}
    >
      <CustomDetailsPage
        isDeletable={true}
        data={productsData}
        setData={setProductsData}
        resource="price adjustment"
        call={"price_adjustments"}
        fields={fields}
      />
    </ContentLayout>
  );
};

export default PriceAdjustmentDetails;
