const localizeDateTime = (date: string): Date => {
  let newDate: Date = new Date(date);
  newDate.setMinutes(newDate.getMinutes() - newDate.getTimezoneOffset());
  return newDate;
};

export const getLocalizedDate = (date: string | undefined): string => {
  const localizedDate: Date = localizeDateTime(date as string);
  const year: number = localizedDate.getFullYear();
  const month: string = ("0" + (localizedDate.getMonth() + 1)).slice(-2);
  const day: string = ("0" + localizedDate.getDate()).slice(-2);

  return day + "." + month + "." + year;
};

export const getLocalizedTime = (date: string): string =>
  localizeDateTime(date).toString().slice(16, 24);
